<template>
    <div class="text-center">
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Spinner"
    }
</script>

<style scoped>

</style>
